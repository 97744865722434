import { clsx } from "clsx";

import { isIframed } from "Services/embed/EmbedUtilService";

import styles from "./PaddedPage.module.scss";

export function PaddedPage({
    children,
    className = "",
    noPadding = false,
    ...props
}: React.PropsWithChildren<{
    className?: string;
    noPadding?: boolean;
    [x: string]: any;
}>): JSX.Element {
    const isAppEmbedded = isIframed();
    // if the padded page contains a <Grid /> or a <LoadingGrid /> as the first child
    // we want to absolutely position the page because we don't want the page
    // to have the width dictated by its contents, but instead by the viewport
    // this page is displayed in a flexbox, so by positioning it absolutely
    // it will still respect the flexbox, it will simply not grow past the viewport
    return (
        <div
            className={clsx(
                styles.padding,
                { [styles.noPadding]: noPadding, [styles.embedded]: isAppEmbedded },
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
}
